import React from "react"
import SlideShow from "./SlideShow"
import Layout from "../components/layout"
import Home from "../components/screens/Home"
import BoxItemBanner from "../components/screens/Home/BoxItemBanner"
import { Helmet } from "react-helmet"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }} homePage>
    <Helmet defer={false}>
        <title>Full stack software company in Vietnam | Open Techiz</title>
        <meta name="description" content="OpenTechiz is a professional full stack software service company in Ha Noi, Vietnam" />
    </Helmet>
    <SlideShow />
    <div className="bottomSlide">
      <BoxItemBanner
        titleContentBanner={"A-Z SERVICE RANGE"}
        containerContentBanner={
          "We handle from auto-scale infrastructure, full stack development, continuous deployment and maintenance"
        }
      />
      <BoxItemBanner
        titleContentBanner={"TRUST BY 100+ CUSTOMERS"}
        containerContentBanner={
          "We take the work seriously with small and big projects. "
        }
      />
      <BoxItemBanner
        titleContentBanner={"HIGH QUALITY"}
        containerContentBanner={
          "Our resource are well trained with internal process and programming pattern. We follow SOLID principle, coding convention and agile methodology."
        }
      />
    </div>
    <Home />
  </Layout>
)

export default IndexPage
