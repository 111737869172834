import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ImageClient from "./ImageClient";
import { bgHomeCustomer } from '../../../images';
import { TabImage, BackgroundImages } from "../../../shared";
import {
  Image,
  Button,
  Col,
  Tab,
  Nav,
  NavItem,
  Form,
} from "react-bootstrap";
import ReCAPTCHA from 'react-google-recaptcha';
import LazyLoad from 'react-lazy-load';
import Fade from 'react-reveal/Fade';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      data: null,
    };
    this._reCaptchaRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderServices = this.renderServices.bind(this);
    this.renderCaseStudy = this.renderCaseStudy.bind(this);
    this.renderCustomer = this.renderCustomer.bind(this);
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  handleSubmit(event, actions) {
    event.preventDefault();
    const form = event.target;
    const data1 = new FormData(form);
    const json = Object.assign(...Array.from(data1, ([x,y]) => ({[x]:y})));

    fetch('https://cms.opentechiz.com/mailer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(json),
    })
    .then(res => res.json())
    .then(data => {
      let messageClass = "message";
      if (data.success){
        messageClass += " success-msg"
        // do something
      } else {
        messageClass += " failure-msg"
        // else something
      }
      this.setState({
        data: data.message,
        messageClass: messageClass
      });
    })
    .catch(function(error){ console.log(error) });

    this._reCaptchaRef.current.reset();
    ReactDOM.findDOMNode(this.messageForm).reset();
  }

  renderCustomer() {
    return (
      <div className="wrapper-customer">
        <BackgroundImages style={{
          backgroundImage: `url(${bgHomeCustomer})`,
          backgroundAttachment: `fixed`,
        }}>
          <p className="service customer">VOICE OF OUR CUSTOMERS</p>
          <div className="box-customer">
            <Col sm={12} lg={12} md={12} xs={12} xl={6} className="link-video">
              <Fade top>
                <iframe
                  data-src={`https://www.youtube.com/embed/U0BQccsA3s8`}
                  className="lazyload"
                />
              </Fade>
            </Col>
            <Col sm={12} lg={12} md={12} xs={12} xl={6} className="content-video">
              <Fade left>
                <div className="bg-content-video">
                  <div className="box-customer-feedback">
                    <p className="characters">&rdquo;</p>
                    <p className="customer-feedback">OnlineBiz Soft is one of the best e-commerce agencies I&rsquo;ve
                      had the pleasure of working with. We took advantage of their
                      impressive design, finishing with a convenient CMS! They provided
                      us with professionalism and reliability at an affordable price,
                      and were always available and quick to respond to our inquiries.
                      <br />
                      <span className="author">- <strong>Robert Staszewski</strong>, Managing Director, <span className="link-author">Macfixit
                      Australia</span></span>
                    </p>
                  </div>
                  <br />
                  <br />
                  <div className="box-customer-feedback" style={{ paddingTop: 18 }}>
                    <p className="characters">&rdquo;</p>
                    <p className="customer-feedback">We have worked together for +3 years, we built and managed the
                    system and now we have grown from single store to be a global
                    brand with stores in 34+ countries.
                      <br />
                      <span className="author">- <strong>AdemTopaloglu</strong>, CEO, <span className="link-author">Glamira</span></span>
                    </p>
                  </div>
                </div>
              </Fade>
            </Col>
          </div>
          <div className="wrapper-contact">
              <div className="box-contact">
                <Col sm={12} lg={12} md={12} className="box-title-contact">
                  <Fade bottom>
                    <h2 className="title-contact">CONTACT US</h2>
                    <div className="content-contact">
                      <p className="p-content-contact">
                        Are you interested in working with us?
                      </p>
                      <p className="p-content-contact">
                        Please leave your information and we will reach out to{" "}
                      </p>
                    </div>
                  </Fade>
                </Col>

                <Col sm={12} lg={12} md={12} className="box-form-contact">
                  <Fade top>
                    <Form onSubmit={this.handleSubmit} ref={ form => this.messageForm = form }>
                      <div className="left col-xl-6">
                        <Form.Group controlId="formBasicFullName">
                          <Form.Control required type="text" name="full-name" placeholder="Full name *" />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Control required type="email" name="email" placeholder="Email *" />
                        </Form.Group>
                        <Form.Group controlId="formBasicCompany">
                          <Form.Control required type="text" name="company" placeholder="Company *" />
                        </Form.Group>
                        <Form.Group controlId="formBasicPhone">
                          <Form.Control required type="text" name="phone" placeholder="Phone *" />
                        </Form.Group>
                      </div>
                      <div className="right col-xl-6">
                        <Form.Group controlId="formBasicTitle">
                          <Form.Control required type="text"  name="title" placeholder="Title *" />
                        </Form.Group>
                        <Form.Group controlId="formBasicAboutUs">
                          <Form.Control required type="text" name="about-us" placeholder="How did you know about us *" />
                        </Form.Group>
                        <Form.Group controlId="formBasicHelp">
                          <Form.Control required as="textarea" name="help" rows="4" placeholder="How do you want us to help *" />
                        </Form.Group>
                      </div>
                      <LazyLoad className="recaptcha">
                        <ReCAPTCHA
                          ref={this._reCaptchaRef}
                          sitekey="6LeJlL4UAAAAACizPm290BBxxlrK_63lsnPlCnxh"
                          onChange={(val) => { this.setState({recapture:val}); }}
                          className="recaptcha-home"
                          theme="dark"
                        />
                      </LazyLoad>
                      <p className={this.state.messageClass}>
                        {this.state.data}
                      </p>
                      <div className="btn-contact">
                        <Button type="submit" className="btn-submit">
                          Submit
                        </Button>
                        <Button type="reset" className="btn-reset">
                          Reset
                        </Button>
                      </div>
                    </Form>
                  </Fade>
                </Col>
              </div>
          </div>
        </BackgroundImages>
      </div>
    );
  }

  renderCaseStudy() {
    return (
      <div className="wrapper-case-study">
        <p className="service">CASE STUDY</p>
        <Fade>
          <div className="hidden-wrapper col-md-8 col-xl-6 left">
            <Image
              alt="900x500"
              className="lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
              data-src={require("../../../images/jpg/pc_mb_casestudy_consumerapp.jpg")}
            />
            <a href="https://closedeal.app/" className="link-case-study">
              <div className="content-study consumer-app"/>
              <div className="content-study">
                <div className="bg-content-study">
                  <p className="title-study">Consumer App</p>
                  <p className="text-study">A line of text to describe this project</p>
                  <span className="btn-study">VIEW CASE STUDY</span>
                </div>
              </div>
            </a>
          </div>
        </Fade>
        <Fade>
          <div className="box-right-hidden-wrapper col-xl-6">
            <div className="hidden-wrapper col-md-8 col-xl-12 glamira">
              <Image
                className="casestudy-pc lazyload"
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../images/jpg/pc_casestudy_glamira.jpg")}
              />
              <Image
                className="casestudy-mb lazyload"
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../images/jpg/mb_casestudy_glamira.jpg")}
              />
              <a href="https://www.glamira.com/" className="link-case-study">
                <div className="content-study glamira">
                  <div className="bg-content-study">
                    <p className="title-study">Glamira</p>
                    <p className="text-study">A line of text to describe this project</p>
                    <span className="btn-study">VIEW CASE STUDY</span>
                  </div>
                </div>
              </a>
            </div>
            <div className="hidden-wrapper col-md-8 col-xl-12 holdsport">
              <Image
                alt="900x500"
                className="lazyload"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../images/jpg/pc_mb_casestudy_holdsport.jpg")}
              />
              <a href="holdsport-store" className="link-case-study">
                <div className="content-study holdsport">
                  <div className="bg-content-study">
                    <p className="title-study">Holdsport</p>
                    <p className="text-study">A line of text to describe this project</p>
                    <span className="btn-study">VIEW CASE STUDY</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </Fade>
      </div>
    )
  }

  handleSelect(key) {
    this.setState({key});
  }

  renderServices() {
    return (
      <div className="wrapper-service">
        <Fade top>
          <p className="service">OUR SEVICES</p>
        </Fade>
        <Tab.Container id="tabs-example" activeKey={this.state.key} onSelect={this.handleSelect}>
          <Col sm={12}>
            <div className="wrapper-nav">
              <Nav className="background">
                <NavItem className="tabs-example-tab-five" id="abc">
                  <Nav.Link eventKey={1}>MOBILE APP DEVELOPMENT</Nav.Link>
                </NavItem>
                <NavItem className="tabs-example-tab-five left">
                  <Nav.Link eventKey={2}>MAGENTO E-COMMERCE</Nav.Link>
                </NavItem>
                <NavItem className="tabs-example-tab-five">
                  <Nav.Link eventKey={3}>MANAGED GOOGLE CLOUD</Nav.Link>
                </NavItem>
                <NavItem className="tabs-example-tab-five left">
                  <Nav.Link eventKey={4}>BLOCKCHAIN</Nav.Link>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col className="edit-service">
            <Fade>
              <Tab.Content>
                <Tab.Pane eventKey={1}>
                  <TabImage
                    image1={require("../../../images/jpg/pc_service_reactnative.jpg")}
                    title1="React-Native & ReactJS"
                    title2="NodeJS & Graphql"
                    content1="Optimization of Performance and Cost"
                    content2="Native solution for Mobile Apps"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <TabImage
                    image1={require("../../../images/jpg/pc_service_magento.jpg")}
                    title1="Magento 1 & Magento 2"
                    title2="E-Commerce Development"
                    content1="Performance Optimization"
                    content2="Auto-scale Cloud based Deployment Solution"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <TabImage
                    image1={require("../../../images/jpg/pc_service_erp.jpg")}
                    title1="Optimized Scalable Google Cloud Hosting "
                    title2="Google Cloud Solution Architecture"
                    content1="Kubernetes"
                    content2="Compute Engine"
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={4}>
                  <TabImage
                    image1={require("../../../images/jpg/pc_service_blockchain.jpg")}
                    title1="Blockchain Development"
                    title2=""
                    content1="Ethereum & Solidity"
                    content2="Hyperledger Fabric"
                  />
                </Tab.Pane>
              </Tab.Content>
            </Fade>
          </Col>
        </Tab.Container>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper-home">
        {this.renderServices()}
        {this.renderCaseStudy()}
        <div className="wrapper-client">
          <Fade top>
            <p className="service">OUR CLIENT</p>
          </Fade>
          <ImageClient src={require("../../../images/asana.png")} />
          <ImageClient src={require("../../../images/spotify.png")} />
          <ImageClient src={require("../../../images/oracle.png")} />
          <ImageClient src={require("../../../images/kickstarter.png")} />
          <ImageClient src={require("../../../images/treehouse.png")} />
          <ImageClient src={require("../../../images/slack.png")} />
          <ImageClient src={require("../../../images/disqus.png")} />
          <ImageClient src={require("../../../images/classy.png")} />
        </div>
        {this.renderCustomer()}
      </div>
    );
  }
}

export default Home;
