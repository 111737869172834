import React, { Component } from 'react';
import {
  Image,
  Col
} from 'react-bootstrap';
import Roll from 'react-reveal/Roll';

class ImageClient extends Component {
  render(){
    return (
      <Col xs={6} md={4} lg={3} className="boxLogo">
        <Roll top>
          <Image
            alt="900x500"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
            data-src={this.props.src}
            className="hoverLogo lazyload"
          />
        </Roll>
      </Col>
    );
  }
};

export default ImageClient;
